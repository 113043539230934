// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-components-footer-tsx": () => import("./../../../src/pages/components/footer.tsx" /* webpackChunkName: "component---src-pages-components-footer-tsx" */),
  "component---src-pages-components-layout-tsx": () => import("./../../../src/pages/components/layout.tsx" /* webpackChunkName: "component---src-pages-components-layout-tsx" */),
  "component---src-pages-components-navbar-tsx": () => import("./../../../src/pages/components/navbar.tsx" /* webpackChunkName: "component---src-pages-components-navbar-tsx" */),
  "component---src-pages-components-social-icons-tsx": () => import("./../../../src/pages/components/social-icons.tsx" /* webpackChunkName: "component---src-pages-components-social-icons-tsx" */),
  "component---src-pages-home-posts-tsx": () => import("./../../../src/pages/home/posts.tsx" /* webpackChunkName: "component---src-pages-home-posts-tsx" */),
  "component---src-pages-home-profile-tsx": () => import("./../../../src/pages/home/profile.tsx" /* webpackChunkName: "component---src-pages-home-profile-tsx" */),
  "component---src-pages-home-publications-tsx": () => import("./../../../src/pages/home/publications.tsx" /* webpackChunkName: "component---src-pages-home-publications-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

